@import "settings/mixins";
@import "settings/variables";
@import "settings/animations";
@import "settings/elements";
@import "settings/parts";

@import "components/account";
@import "components/home";
@font-face {
    font-family: Lato-Bold;
    src: url('../fonts/Lato-Bold.ttf');
}
@font-face {
    font-family: Lato-Regular;
    src: url('../fonts//Lato-Regular.ttf');
}